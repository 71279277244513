import React from 'react';
import './Gallery.css';
import { PhotoAlbum } from 'react-photo-album';
import { photos } from './images';

const renderPhoto = ({ photo, layoutOptions, imageProps }) => (
  <div
    className="photo-container"
    style={{
      width: imageProps.style.width,
      position: 'relative'
    }}
  >
    <img {...imageProps} alt={photo.caption} style={{ width: "100%", padding: 0 }} />
    <div className="caption-overlay">
      {photo.caption}
    </div>
  </div>
);

export default function Gallery() {
  return (
    <PhotoAlbum
      layout="rows"
      photos={photos}
      renderPhoto={renderPhoto}
    />
  );
}
