import React, { useState } from 'react';
import events from '../../data/events.json';
import Modal from '../EventModal/EventModal';
import './Timeline.css';

const Timeline = () => {
  const [modalContent, setModalContent] = useState(null);

  const handleClick = (event) => {
    setModalContent(event);
  };

  const handleClose = () => {
    setModalContent(null);
  };

  return (
    <>
      <div className="timeline-container">
        {events.map((event, index) => (
          <div key={event.year} className={`timeline-item ${index % 2 === 0 ? 'right' : 'left'}`}>
            <div className="timeline-content" onClick={() => handleClick(event)}>
              <h2>{event.year}</h2>
              <h3>{event.title}</h3>
              <p>{event.description}</p>
            </div>
          </div>
        ))}
      </div>
      {modalContent && <Modal content={modalContent} onClose={handleClose} />}
    </>
  );
};

export default Timeline;
