import React, { useState, useEffect } from 'react';
import TabNav from './components/Tabs/TabNav';
import Backstory from './components/Backstory/Backstory';
import Characters from './components/Characters/Characters';
import Gallery from './components/Gallery/Gallery';
import Login from './components/Login/LoginForm';
import Footer from './components/Footer/Footer';
import './App.css';

const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentTab, setCurrentTab] = useState('backstory');

  useEffect(() => {
    const userSession = getCookie("userSession");
    if (userSession) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (loggedIn) => {
    setIsAuthenticated(loggedIn);
  };

  const handleLogout = () => {
    eraseCookie("userSession");
    setIsAuthenticated(false);
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 'backstory':
        return <Backstory />;
      case 'characters':
        return <Characters />;
      case 'gallery':
        return <Gallery />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <div className='content'>
            <div className="logout-container">
              <button onClick={handleLogout} className="logout-button">Logout</button>
            </div>
            <h1>Tristan Ashwood</h1>
            <TabNav tabs={[{ id: 'backstory', label: 'Backstory' },
            { id: 'characters', label: 'Characters' },
            { id: 'gallery', label: 'Gallery' }]}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab} />
            <div className="tab-content">
              {renderTabContent()}
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <div className='content'>
            <Login onLogin={handleLogin} />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;