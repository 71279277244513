import React from 'react';
import Timeline from '../Timeline/Timeline';

const Backstory = () => {
  return (
    <div>
      <Timeline />
    </div>
  );
};

export default Backstory;