import React, { useState } from 'react';
import CharacterModal from '../CharacterModal/CharacterModal';
import { characterData } from './characterData';
import './Characters.css';

const Characters = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const handleCharacterClick = character => {
    setSelectedCharacter(character);
  };

  const handleCloseModal = () => {
    setSelectedCharacter(null);
  };

  return (
    <div>
      <div className="character-grid">
        {characterData.map(character => (
          <div key={character.id} className="character-box" onClick={() => handleCharacterClick(character)}>
            <img src={character.imageUrl} alt={character.name} className="character-image"/>
            <div className="character-info">
              <h3>{character.name}</h3>
              <p>{character.relation}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedCharacter && <CharacterModal content={selectedCharacter} onClose={handleCloseModal} />}
    </div>
  );
};

export default Characters;
