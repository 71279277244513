import BridgetownOne from '../../assets/gallery/Bridgetown-one.jpg'
import BridgetownTwo from '../../assets/gallery/Bridgetown-two.jpg'
import BridgetownThree from '../../assets/gallery/Bridgetown-three.jpg'
import BridgetownFour from '../../assets/gallery/Bridgetown-four.jpg'
//import BridgetownSix from '../../assets/gallery/Bridgetown-six.png'
import BridgetownSeven from '../../assets/gallery/Bridgetown-seven.png'
import BlackwallOne from '../../assets/gallery/Blackwall.jpg'
import SanIgnacioOne from '../../assets/gallery/San-Ignacio.jpg'
import Camp from '../../assets/gallery/privateer-camp.jpg'
import Coastline from '../../assets/gallery/coastline.jpg'
import LuxtonOne from '../../assets/gallery/luxton-one.jpg'
import LuxtonTwo from '../../assets/gallery/luxton-two.jpg'
import LuxtonThree from '../../assets/gallery/luxton-three.jpg'

export const photos = [
  // {
  //   src: BridgetownSix,
  //   width: 1024,
  //   height: 1024,
  //   caption: "Medical office bridgetown",
  // },
  { src: BridgetownSeven, 
    width: 1024, 
    height: 1024,
    caption: "Medical supplies bridgetown", 
  },
  {
    src: BridgetownOne,
    width: 2000,
    height: 833,
    caption: "Bridgetown",
  },
  {
    src: BridgetownTwo,
    width: 1920,
    height: 800,
    caption: "Bridgetown",
  },
  {
    src: BridgetownThree,
    width: 1920,
    height: 800,
    caption: "Bridgetown",
  },
  {
    src: BridgetownFour,
    width: 1920,
    height: 800,
    caption: "Bridgetown at night",
  },
  { src: SanIgnacioOne, 
    width: 1920, 
    height: 1097,
    caption: "San Ignacio",
  },
  { src: BlackwallOne, 
    width: 842, 
    height: 622,
    caption: "Blackwall", 
  },
  { src: Camp, 
    width: 2160, 
    height: 1215,
    caption: "Privateers camping", 
  },
  { src: Coastline, 
    width: 1500, 
    height: 617,
    caption: "Tropical coast-line", 
  },
  { src: LuxtonOne, 
    width: 3840, 
    height: 1951,
    caption: "Luxton", 
  },
  { src: LuxtonTwo, 
    width: 1920, 
    height: 942,
    caption: "Luxton", 
  },
  { src: LuxtonThree, 
    width: 1920, 
    height: 1029,
    caption: "Luxton", 
  }
];