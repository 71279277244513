import React, { useEffect, useState } from 'react';
import './EventModal.css';

const EventModal = ({ content, onClose }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(true);
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setActive(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  if (!content) return null;

  return (
    <div className={`modal-overlay${active ? ' active' : ''}`} onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{content.year}</h2>
        <h3>{content.title}</h3>
        <p className='full-text'>{content.fullText}</p>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default EventModal;
