import React from 'react';
import './TabNav.css';

const TabNav = ({ tabs, currentTab, setCurrentTab }) => {
  return (
    <nav className="tab-nav">
      {tabs.map(tab => (
        <button
          key={tab.id}
          className={currentTab === tab.id ? 'tab-active' : ''}
          onClick={() => setCurrentTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  );
};

export default TabNav;