import React, { useEffect, useState } from 'react';
import './CharacterModal.css'; // Make sure this path is correct

const CharacterModal = ({ content, onClose }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(true);
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setActive(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const renderTextWithLineBreaks = text => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br /> <br />
      </React.Fragment>
    ));
  };

  if (!content) return null;

  return (
    <div className={`modal-overlay${active ? ' active' : ''}`} onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{content.name}</h2>
        <img src={content.imageUrl} alt={content.name} className="modal-character-image"/>
        <p className='full-text'>{renderTextWithLineBreaks(content.fullText)}</p>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default CharacterModal;
