import Thomas from '../../assets/gallery/thomas.png'
import Nathanial from '../../assets/gallery/Nathanial.jpg'
import Martha from '../../assets/gallery/martha.jpg'
import Barbarossa from '../../assets/gallery/barbarossa.jpg'
import Bocour from '../../assets/gallery/bocour.jpg'

export const characterData = [
    {
        id: 1,
        name: 'Thomas',
        relation: 'Tristan\'s father',
        imageUrl: Thomas,
        fullText: `Tristan's dad was a tough and hardworking doctor in Bridgetown, part of Guerreros' Barnabas region. He spent his days treating sick people, often struggling with not having enough supplies. \n  Before Martha's passing, Thomas wasn't very involved in Tristan's life, often consumed by his work. However, after losing Martha, Thomas' approach changed. The hard life made him serious and stern. He raised Tristan with a firm hand, teaching him about discipline and the importance of hard work. He wasn't much for being soft or overly affectionate. Instead, he showed his care by making sure Tristan was prepared for real life. He taught him a lot about being a doctor too, showing him the ropes of the job and how to handle the tough parts of it. \n There were good times, though. Sometimes, he'd share stories about his work, giving Tristan a peek into his world. These moments let Tristan see how much his dad cared about his job and helping others, even though it was really tough on him. \n As Tristan got older, he noticed his dad getting worn out. The long hours and worry about money started to affect his health. His dad, who used to be strong and steady, began to weaken. \n When Tristan was 19, his dad's health got really bad. He caught tuberculosis, the same illness he spent his life fighting against in others. Losing his dad hit Tristan hard. He was left with not just the sadness of losing his dad but also the responsibility of taking over his work and dealing with the debts.`,
    },
    {
        id: 2,
        name: 'Martha',
        relation: 'Tristan\'s mother',
        imageUrl: Martha,
        fullText: `Martha was a kind and loving woman who lived in Bridgetown with her family. Tristan doesn't remember much about her, as she passed away when he was just six years old. The main reminder he has of her is an oil painting, showing her gentle expression and warm eyes. \n Tristan's memories of Martha are faint, but he recalls a sense of comfort and love when he was around her. She was the heart of their small home, providing a nurturing and peaceful environment. \n Her passing left a deep impact on the family, especially on young Tristan, who would endlessly gaze at her painting, trying to connect with the loving memories he associated with her.`,
    },
    {
        id: 3,
        name: 'Nathanial Hawkthorne',
        relation: 'Previous captain',
        imageUrl: Nathanial,
        fullText: `Nathanial Hawkthorne was a respected privateer captain known for his strategic mind and fair leadership. Tristan held great respect for Nathanial, seeing him as a guiding figure. Under Nathanial's command, Tristan found not only adventure but also a way to escape the crushing debt he inherited from his father. \n Nathanial's sense of justice and fairness played a big part in how Tristan viewed the world. From Nathanial, Tristan learned the importance of quick thinking and making tough decisions while maintaining a moral compass. This experience helped Tristan to navigate through some of the toughest times in his life. \n Tragically, Nathanial's life was cut short during a mutiny led by Barbarossa, a feared member of his crew. The betrayal and loss of Nathanial deeply affected Tristan, prompting him to leave the crew and chart a new path. His death marked the end of an era for Tristan, leaving a lasting impact on his life and values.`,
    },
    {
        id: 4,
        name: 'Barbarossa',
        relation: 'Pirate captain',
        imageUrl: Barbarossa,
        fullText: `Barbarossa`,
    }, 
    {
        id: 5,
        name: 'Ayo',
        relation: `Tristan's former love`,
        imageUrl: Bocour,
        fullText: `Ayo was an orc Bocour from the colony of Saint Germain, located in the western half of Hispéria, the second largest island in Guerreros. Known for her deep understanding of voodoo magic, she was both formidable and captivating. \n Tristan and Ayo met while serving on Nathanial Hawkthorne's crew, and their connection was immediate. Ayo's knowledge of voodoo and her skills as a healer were matched by her strength of character. She was a significant influence on Tristan, teaching him not only the art of voodoo magic but also the complexities of love and trust. \n Their relationship, however, took a turn during the mutiny led by Barbarossa. Ayo chose to stay with the new pirate crew, a decision that left Tristan heartbroken and alone. Her choice marked the end of their relationship, leaving Tristan with memories of their time together and the voodoo skills she had passed on to him.`,
    },
];